import Quill from 'vue-quill-editor'

let BlockEmbed = Quill.Quill.imports['blots/embed'];
class ImageBlot extends BlockEmbed {
    static create(value) {
        let node = super.create();
        node.setAttribute('src', value.src);
        return node;
    }

    static value(node) {
        return {
            src: node.getAttribute('src'),
        }
    }
}
ImageBlot.blotName = 'image';
ImageBlot.tagName = 'img';
ImageBlot.className = 'yy-image'
export default ImageBlot